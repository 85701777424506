<script setup lang="ts">
import { useRouteData } from '~/api/drupal'

definePageMeta({
  middleware: 'get-layout',
})
const { entity, breadcrumbs } = await useRouteData(useRoute().path)
useDrupalMetaTags(entity)
</script>

<template>
  <component :is="entity?.__typename as any" v-if="!!entity" v-bind="{ entity, breadcrumbs }" />
</template>
